import React, { Component } from "react"
import PropTypes from "prop-types"
import Clipboard from "clipboard"
import styled from "styled-components"

import { mixins } from "@styles"

const StyledContainer = styled.figure`
  position: relative;
  border-radius: 6px;
  font-weight: 400;
  overflow: hidden;
  padding: 0;
  margin: 0;
  max-width: 100%;
  ${mixins.transition("box-shadow, background-color")}
  color: var(--whitesmoke);
  background-color: var(--darkcharcoal);

  ${({ highlight }) =>
    highlight &&
    `
    border-radius: 2px;
    box-shadow: 0 0 0 2px #ffdd57;
  `}

  code, pre {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    font-family: monospace;
  }

  pre {
    -webkit-overflow-scrolling: touch;
    font-size: 0.9em;
    overflow: auto;
    padding: 1.25rem 1.5rem;
    white-space: pre-line;
    word-wrap: normal;
    word-break: break-all;
    ${mixins.transition("background-color")}
  }

  code {
    font-size: 0.875em;
    font-weight: 400;
    padding: 0.25em 0.5em 0.25em;
  }

  pre code {
    background-color: transparent;
    color: currentColor;
    font-size: 1em;
    padding: 0;
  }
`

const CopyButton = styled.button`
  border-radius: 2px;
  /*font-size: .75rem;*/
  height: 2.5em;
  line-height: 1.5;
  background: 0 0;
  border: none;
  cursor: pointer;
  font-size: 0.625rem;
  outline: 0;
  padding-bottom: 0;
  padding-top: 0;
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  ${mixins.transition("background-color, color")}
  font-weight: 700;
  color: inherit;

  &:hover,
  &:focus {
    background-color: #ffdd57;
    color: rgba(0, 0, 0, 0.7);
  }
`

class CodeBlock extends Component {
  state = { isHighlight: false }

  componentDidMount() {
    const button = this.button
    const input = this.input

    this.clipboard = new Clipboard(button, {
      target: () => input,
    })
  }

  componentWillUnmount() {
    this.clipboard.destroy()
  }

  onMouseEnterCopy = () => {
    this.setState(() => ({ isHighlight: true }))
  }

  onMouseLeaveCopy = () => {
    this.setState(() => ({ isHighlight: false }))
  }

  render() {
    return (
      <StyledContainer highlight={this.state.isHighlight}>
        <pre>
          <code
            ref={element => {
              this.input = element
            }}
          >
            {this.props.children}
          </code>
        </pre>
        <CopyButton
          ref={element => {
            this.button = element
          }}
          onMouseEnter={this.onMouseEnterCopy}
          onMouseLeave={this.onMouseLeaveCopy}
        >
          Copy
        </CopyButton>
      </StyledContainer>
    )
  }
}

CodeBlock.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CodeBlock
