import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Switch from "@components/switch"
import TopButton from "@components/topbutton"
import CodeBlock from "@components/codeblock"
import Section from "@styles/Section"
import UnderlineLink from "@styles/UnderlineLink"
import { breakpoints } from "@styles"

import sshKey from "raw-loader!@files/id_rsa.pub"
import gpgKey from "raw-loader!@files/public-key.gpg"

const StyledContainer = styled.div`
  h1 {
    font-size: 4rem;
    margin: 10px 0;
  }

  h2 {
    font-size: 1.5rem;

    ${breakpoints.mobile`font-size: 1.25rem;`}
    ${breakpoints.smallMobile`font-size: 1.1rem;`}
  }
`

const StyledName = styled.div`
  position: absolute;
  top: 25px;
  left: 30px;
  font-weight: 700;
  color: var(--blue);
  font-size: 1.5rem;

  ${breakpoints.mobile`
    top: 20px;
    left: 25px;
    font-size: 1.25rem;
  `}
`

const KeysPage = () => (
  <Layout>
    <SEO title="Public Keys" />
    <Switch />
    <StyledContainer>
      <StyledName>
        <UnderlineLink as={Link} to="/">
          Thibault Ehrhart
        </UnderlineLink>
      </StyledName>
      <Section>
        <Section.Content>
          <h2>SSH key</h2>
          <CodeBlock>{sshKey}</CodeBlock>
          <h2>GPG key</h2>
          <CodeBlock>{gpgKey}</CodeBlock>
        </Section.Content>
      </Section>
    </StyledContainer>
    <TopButton />
  </Layout>
)

export default KeysPage
